/* eslint-disable react/jsx-no-useless-fragment */
import { Route as Rauter } from 'react-router-dom';

import {
  AppointmentPatient,
  Favorite,
  Specialists,
  Tests
} from '../pages';
import PatientProfile from '../pages/Settings/Profile';

import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { Switch } from 'react-router-dom';
import CancelMeet from '../../../shared/pages/Cancel';
import Legal from '../../../shared/pages/legal';
import GalleryArticle from '../pages/Gallery/Article';
import GalleryVideo from '../pages/Gallery/Video';
import Dashboard from '../pages/dashboard';
import Payment from '../pages/payment';
import Billing from '../pages/payment/Billing';
import Cancel from '../pages/payment/StripeCheckout/Cancel/Cancel';
import Success from '../pages/payment/StripeCheckout/Success/Success';

const NotFound = () => {
  return (
    <div className="hero h-4/5 ">
        <div className="hero-content text-accent text-center">
          <div className="max-w-md">
            <FaceFrownIcon className="h-48 w-48 inline-block" />
            <h1 className="text-5xl  font-bold">Não encontramos o que você procura.</h1>
          </div>
        </div>
      </div>
  )
}



const Routes = () => {
  return (
    <Switch>
      <Rauter path="/" exact component={Dashboard} isPrivate />
      <Rauter path="/especialistas" exact component={Specialists} isPrivate />
      <Rauter path="/testes" exact component={Tests} isPrivate />
      <Rauter path="/consultas" exact component={AppointmentPatient} isPrivate />
      <Rauter path="/favoritos" exact component={Favorite} isPrivate />
      <Rauter path="/perfil" exact component={PatientProfile} isPrivate />
      <Rauter path="/pagamento" exact component={Payment} isPrivate />
      <Rauter path="/sucesso" component={Success} isPrivate />
      <Rauter path="/cancelado" exact component={Cancel} isPrivate />
      <Rauter path="/historico" exact component={Billing} isPrivate />
      <Rauter path="/consulta-cancelada" exact component={CancelMeet} isPrivate />
      <Rauter path="/galeria" exact component={GalleryVideo} isPrivate />
      <Rauter path="/artigos" exact component={GalleryArticle} isPrivate />
      <Rauter path="/legal" exact component={Legal} isPrivate />
  
      <Rauter path="*" component={NotFound}  />
        
    </Switch>
  )
}

export default Routes;
