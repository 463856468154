import { useEffect, useState } from 'react';

import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon';
import UserGroupIcon from '@heroicons/react/24/outline/UserGroupIcon';
import UsersIcon from '@heroicons/react/24/outline/UsersIcon';
import { supabase } from '../../../../data/supabase';
import BarChart from '../../../../shared/charts/components/BarChart';
import LineChart from '../../../../shared/charts/components/LineChart';
import DashboardStats from './components/DashboardStats';

const Home = () => {
  const [numberUser, setNumberuser] = useState();
  const [numberUserSpec, setNumberuserSpec] = useState();
  useEffect(() => {
    const numberUsers = async () => {
      const { data: patient } = await supabase.from('patient').select('*');

      setNumberuser(patient);
    };

    const numberUserSpec = async () => {
      const { data: spec } = await supabase.from('specialist').select('*');
      setNumberuserSpec(spec);
    };

    numberUsers();
    numberUserSpec();
  }, []);

  const statsData = [
    {
      title: 'Total de pacientes',
      value: numberUser?.length,
      icon: <UserGroupIcon className="w-8 h-8" />,
      description: '↗︎ 2300 (22%)',
    },
    {
      title: 'Total de Especialistas',
      value: numberUserSpec?.length,
      icon: <UserGroupIcon className="w-8 h-8" />,

      description: 'Atual',
    },
    {
      title: 'Agendamentos pendentes',
      value: '450',
      icon: <CircleStackIcon className="w-8 h-8" />,
      description: 'agendamentos',
    },
    {
      title: 'Usuarios ativos na plataforma',
      value: '1',
      icon: <UsersIcon className="w-8 h-8" />,
      description: 'ativos',
    },
  ];

  return (
    <>
      {window.location.pathname === "/" && (
        <>
          <div className="flex col-span-12 gap-4">
            {statsData.map((d, k) => {
              return <DashboardStats key={k} {...d} colorIndex={k} />;
            })}
          </div>

          <div className="flex col-span-12 gap-6">
            <LineChart />
            <BarChart />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
