import axios from 'axios';
import { useEffect, useState } from 'react';

function useGallery() {
  const [fileList, setFileList] = useState([]);

  const getFileList = async () => {
    axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/files-list`)
      .then((response) => setFileList(response.data.files))
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFileList();
  }, []);

  return {
    fileList,
  };
}

export default useGallery;
