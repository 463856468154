function ModalSuccessSingup() {
  return (
    <>
      <div className="grid ml-2">
        <p className="text-lg">
          Obrigado por se cadastrar. Enviamos um email de verificação para o
          endereço fornecido.
        </p>
        <span className="text-sm mt-6 animate-bounce">
          ⚠️ Importante: Se você não encontrar o email na sua caixa de entrada,
          por favor, verifique também as pastas de Lixo Eletrônico e/ou Spam.
        </span>

        <div className="modal-action mt-12">
          <a href="/login">
            <button
              onClick={() => window.location.replace('login')}
              className="rounded-[8px] text-white font-bold p-[12px] bg-[#3BBED0] border-[#3BBED0]"
            >
              Faça Login
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default ModalSuccessSingup;
