
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import usePayment from '../modules/patients/hooks/UsePayment';
import { useAuth } from '../shared/context/useAuth';

function Header() {
  const { admin, patient } = useAuth();
  const history = useHistory();
  const { voucher } = usePayment();

  function logoutUser() {
    localStorage.clear();
    window.location.href = `https://www.embraceinternacional.com/`;
  }

  const avatarUrl = useSelector((state) => state.avatar.avatar);

  return (
    <div className="navbar flex justify-between dark:bg-base-100 bg-white  z-10 shadow-sm ">
      <div className="">
        <label
          htmlFor="left-sidebar-drawer"
          className="btn btn-primary drawer-button laptop:hidden desktop:hidden"
        >
          <Bars3Icon className="h-5 inline-block w-5" />
        </label>
      </div>

      <div className="order-last">
        {/* saldo */}
        {Object.keys(patient)?.length > 0 && (
          <div className="dropdown dropdown-bottom dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="bg-[#3bbdcf] text-sm font-bold text-white rounded-xl p-1 m-1"
            >
              Carteira
            </div>
            <div
              tabIndex={0}
              className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-white text-primary-content"
            >
              <div className="card-body text-[black]">
                <div className="">
                  <div className="stat-title text-[#666]">Seu Saldo</div>
                  {voucher && voucher[0] ? (<div className="badge badge-success text-sm mb-1 block">{voucher[0]?.max || 0} {voucher[0]?.type}</div>) : <div className="badge badge-warning text-sm mb-1">0</div>}
                  {voucher && voucher[1] ? (<div className="badge badge-success text-sm mb-1 block">{voucher[1]?.max || 0} {voucher[1]?.type}</div>) : <div className="badge badge-warning text-sm">0</div>}
                  {voucher && voucher[2] ? (<div className="badge badge-success text-sm block">{voucher[2]?.max || 0} {voucher[2]?.type}</div>) : ''}
                  <div className="stat-actions">
                    {voucher?.some((item) => item?.max > 1) ||
                      (!voucher?.length && (
                        <button
                          onClick={() => history.push('/pagamento')}
                          className="btn btn-sm btn-success"
                        >
                          Adicionar saldo
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Profile icon, opening menu on click */}
        <div className="dropdown dropdown-end ml-4">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img
               
                src={
                  avatarUrl ||'https://cdn-icons-png.flaticon.com/512/6596/6596121.png' // Caminho de fallback
                }
                alt="avatar"
              />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            {Object.keys(admin)?.length === 0 && (
              <>
              <li className="justify-between">
              <Link
                to="/perfil"
              >
                Editar Perfil
              </Link>
            </li>
            <div className="divider mt-0 mb-0" />
              </>
            )}

            <li>
              <span onClick={logoutUser}>Sair</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
