import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../../../data/supabase';

function useAppointment() {
  const [appointments, setAppointments] = useState([]);

  const getProfile = async (user) => {
    try {
      const { data: specialist } = await supabase
        .from('specialist')
        .select()
        .eq('id_user', user.id);

      if (specialist) {
        return specialist;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        return await getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getAppointments = async () => {
    const specialist = await getUser();
    const { data: appointments } = await supabase
      .from('appointment')
      .select(
        `
      id,
      start,
      end,
      status,
      meetLink,
      patient ( id, name )
    `,
      )
      .eq('specialist_id', specialist[0]?.id);

    if (appointments) {
      setAppointments(appointments);
    }
  };

  useEffect(() => {
    getAppointments();
  }, []);

  return {
    appointments,
  };
}

export default useAppointment;
