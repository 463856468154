import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesSpecialists = [
  {
    path: '/',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Inicio',
  },
  {
    path: '/agendamentos', 
    icon: <CalendarDaysIcon className={iconClasses} />,
    name: 'Agendamentos', 
  },

  {
    path: '/con', 
    icon: <Cog6ToothIcon className={`${iconClasses} inline`} />,
    name: 'Configurações', 
    submenu: [
      {
        path: '/perfil', 
        icon: <UserIcon className={submenuIconClasses} />,
        name: 'Perfil', 
      },
     
    ],
  },
  {
    path: '/legal', 
    icon: <ShieldCheckIcon className={iconClasses} />,
    name: 'Legal', 
  },
  
];

export default routesSpecialists;
