import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { supabase } from '../../data/supabase';
import { openModal } from '../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../utils/globalConstantUtil';
import InputText from '../components/Input/InputText';
import useConsent from '../hooks/UseConsent';
import PhoneInput from './components/Phone/PhoneInput';

const Register = () => {
  const INITIAL_REGISTER_OBJ = {
    name: '',
    password: '',
    phoneNumber: '',
    emailId: '',
  };


  const dispatch = useDispatch();
  const [values, setValues] = useState(INITIAL_REGISTER_OBJ);
  const [phoneNumber, setPhoneNumber] = useState();
  const [typeUser, setTypeUser] = useState();
  const { saveTermsOfUse } = useConsent();
  const updateFormValue = ({ updateType, value }) => {
    setValues({ ...values, [updateType]: value, phoneNumber });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    localStorage.clear();
    try {
      const termsOfUseAccepted = document.getElementById('termsofuse').checked;
      console.log('Type', typeUser);
      if (termsOfUseAccepted) {
        const { error } = await supabase.auth.signUp({
          email: values.emailId,
          password: values.password,
          options: {
            data: {
              name: values.name,
              email: values.emailId,
              mobile_number: phoneNumber,
              role: typeUser,
              terms_of_use: termsOfUseAccepted,
            },
            emailRedirectTo: `https://app.embraceinternacional.com/`,
          },
        });

        saveTermsOfUse({
          termsOfUse: termsOfUseAccepted,
          email: values.emailId,
        });

        axios({
          method: 'POST',
          url: 'https://webhook.sellflux.app/webhook/custom/lead/9f4a44a16dec1a082e77acf25f2a5992?name=name&email=email&phone=phone',

          data: {
            name: values.name,
            email: values.emailId,
            phone: phoneNumber,
          },
        }).then((res) => res);

        if (!error) {
          toast.success('Verifique seu email');
          dispatch(
            openModal({
              title: 'Cadastro Realizado com Sucesso ✅',
              bodyType: MODAL_BODY_TYPES.SUCCESS_SIGNUP,
              extraObject: { message: 'Teste' },
            }),
          );
        }

        if (error) {
          toast.error(
            error.message === 'Signup requires a valid password'
              ? 'Coloque um email e senha'
              : error.message,
          );
        }
      } else {
        toast.error('É obrigatório aceitar os termos e condições!');
      }

    } catch (error) {
      toast.error(error);
    } finally {
      const nullFunc = () => {}
    }
  };

  return (
    <div className="max-w-full bg-white flex items-center laptop:pt-12 laptop:pb-12 gap-64 justify-center">
      <div className="laptop:col-span-6 col-span-12 laptop:block hidden">
        <img src="/image.svg" className="" alt="hero" />
      </div>
      <div className="laptop:col-span-6 col-span-12 h-full laptop:p-12 laptop:bg-[#F4F7FB] laptop:rounded-3xl overflow-hidden">
        <div className="flex justify-center flex-col items-center">
          <img
            src="/newlogo.png"
            alt="logo"
            width={120}
            height={120}
            className="justify-center items-center text-center "
          />
          <h2 className="font-work font-bold text-[26px] leading-[54px] -tracking-2 text-center text-black">
            Cadastrar
          </h2>
        </div>

        <div className="flex items-center justify-center">
         
            <button className="flex items-center justify-center mr-6 font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0]">
              Se Cadastar
            </button>
          <a
            href="/login"
          >
            <button className="flex items-center justify-center  font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm ml-2 laptop:text-base text-black  p-[24px] bg-[#81818159]">
              Entrar
            </button>
          </a>
        </div>

        <form className="mx-auto mt-6 max-w-[450px]" onSubmit={submitForm}>
          <div>

           <Select
                name="typeUser"
                value={typeUser?.label}
                placeholder="Escolhe o tipo de perfil"
                options={[{value: 'cli', label: "Paciente"}, {value: 'pro', label: "Terapeura"}]}
                classNamePrefix="select"
                required
                data-required-message="E-Mail or Username is Required!"
                className="select-bordered"
                onChange={(e) => setTypeUser(e?.value)}
              />
              {!typeUser && (
        <p style={{ color: 'red' }}>Por favor, selecione uma opção.</p> // Mensagem de erro ou personalizada
      )}
            <InputText
              type="name"
              defaultValue={INITIAL_REGISTER_OBJ.name}
              updateType="name"
              containerStyle="mt-4"
              labelTitle="Nome"
              updateFormValue={updateFormValue}
            />

            <PhoneInput setPhoneNumber={setPhoneNumber} />

            <InputText
              type="emailId"
              defaultValue={INITIAL_REGISTER_OBJ.emailId}
              updateType="emailId"
              containerStyle="mt-4"
              labelTitle="Email"
              updateFormValue={updateFormValue}
            />

            <InputText
              defaultValue={INITIAL_REGISTER_OBJ.password}
              type="password"
              updateType="password"
              containerStyle="mt-4"
              labelTitle="Senha"
              updateFormValue={updateFormValue}
            />
          </div>
          <div className="mt-6 flex items-center justify-center">
            <label
              className="relative -ml-2.5 flex cursor-pointer items-center rounded-full p-3"
              htmlFor="termsofuse"
            >
              <input
                type="checkbox"
                name="termsofuse"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                id="termsofuse"
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <label
              className="mt-px font-light text-gray-700 cursor-pointer select-none"
              htmlFor="termsofuse"
            >
              <p className="flex items-center font-sans text-sm antialiased font-normal leading-normal text-gray-700">
                Concordo com os
                <a
                  href="/legal.pdf"
                  target="_blank"
                  className="font-medium transition-colors hover:text-gray-900"
                  rel="noreferrer"
                >
                  &nbsp;Termos e Condições
                </a>
              </p>
            </label>
          </div>
          <div className="mt-6 flex items-center justify-center">
            <button className="flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm desktop:text-base text-white p-[24px] bg-[#3BBED0] border-[#3BBED0] border-2">
              Cadastrar
            </button>
          </div>
        </form>

        {/* <div className="flex items-center justify-between mt-4">
          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />

          <a
            href="#"
            className="font-work font-medium leading-5 text-[16px] text-[#818181] text-center"
          >
            ou se preferir
          </a>

          <span className="w-1/5 border-b border-[#818181]  lg:w-1/3" />
        </div> */}

        {/* <div className="flex items-center justify-center mt-6 -mx-2">
          <button
            type="button"
            onClick={signUpWithGoogle}
            className="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center  mr-2 mb-2"
          >
            <svg
              className="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              />
            </svg>
            Faça login com Google
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Register;
