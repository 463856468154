/** Icons are imported separatly to reduce build time */
import {
  BookOpenIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  FilmIcon,
  HeartIcon as HeartOutLine,
  PhotoIcon,
  ShieldCheckIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Cog6ToothIcon from '@heroicons/react/24/outline/Cog6ToothIcon';
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesPatients = [
  {
    path: '/',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Início',
  },
  {
    path: '/especialistas', 
    icon: <CalendarDaysIcon className={iconClasses} />,
    name: 'Especialistas', 
  },
  {
    path: '/consultas', 
    icon: <CalendarDaysIcon className={iconClasses} />,
    name: 'Consultas', 
  },
  {
    path: '/favoritos', 
    icon: <HeartOutLine className={iconClasses} />,
    name: 'Meus Favoritos', 
  },
  {
    path: '/testes', 
    icon: <ClipboardDocumentListIcon className={iconClasses} />,
    name: 'Faça testes', 
  },
  {
    path: '/pagamento', 
    icon: <CreditCardIcon className={`${iconClasses} inline`} />,
    name: 'Pagamentos', 
    submenu: [
      {
        path: '/pagamento', 
        icon: <CurrencyDollarIcon className={submenuIconClasses} />,
        name: 'Comprar Créditos', 
      },
      {
        path: '/historico', 
        icon: <WalletIcon className={submenuIconClasses} />,
        name: 'Histórico Pagamentos', 
      },
    ],
  },
  {
    path: '/galeria', 
    icon: <PhotoIcon className={`${iconClasses} inline`} />,
    name: 'Galeria de Conteúdo', 
    submenu: [
      {
        path: '/galeria', 
        icon: <FilmIcon className={submenuIconClasses} />,
        name: 'Vídeos', 
      },
      {
        path: '/artigos', 
        icon: <BookOpenIcon className={submenuIconClasses} />,
        name: 'Artigos', 
      },
    ],
  },

  {
    path: '/con', 
    icon: <Cog6ToothIcon className={`${iconClasses} inline`} />,
    name: 'Configurações', 
    submenu: [
      {
        path: '/perfil', 
        icon: <UserIcon className={submenuIconClasses} />,
        name: 'Perfil', 
      },
    ],
  },
  {
    path: '/legal', 
    icon: <ShieldCheckIcon className={iconClasses} />,
    name: 'Legal', 
  },
];

export default routesPatients;
