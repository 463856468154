import axios from 'axios';
import { useEffect, useState } from 'react';
import usePayment from '../../../../hooks/UsePayment';

const Success = () => {
  const [payment, setPayment] = useState();
  const { balanceUpdate } = usePayment();
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const sessionId = queryParameters.get('session_id');
    axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/order/success/${sessionId}`)
      .then((response) => {
        setPayment(response.data);
        balanceUpdate(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (loading) {
    document.body.classList.add('loading-indicator');
  } else {
    document.body.classList.remove('loading-indicator');
    
  }

  return (
    <div className="w-full col-span-12">
      <div className="mx-auto">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          />
        </svg>
        <div className="text-center">
          <h3 className="laptop:text-2xl text-base text-gray-900 font-semibold text-center">
            Pagamento realizado com sucesso!
          </h3>
          <p className="text-gray-600 my-2">
            Aproveite agora para agendar uma consulta.
          </p>
          <p> Obrigado! </p>
          <div className="py-10 text-center">
            <a href='/historico'>
            <button className="text-sm desktop:text-[14px] desktop:py-2 desktop:px-5 px-[64px] py-[12px] mt-11 mb-5 text-white font-bold p-[24px] bg-[#3BBED0] border-0 rounded-[5px]">
              Veja seu histórico
            </button>

            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
