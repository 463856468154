import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../../../data/supabase';

function useSchedule() {
  const [schedule, setSchedule] = useState([]);
  const [specialist, setSpecialist] = useState([]);

  const getSchedule = async (specialist) => {
    const { data: schedule } = await supabase
      .from('schedule')
      .select()
      .eq('specialist_id', specialist.id);

    if (schedule) {
      setSchedule(schedule[0]);
    }
  };

  const getProfile = async (user) => {
    const { data: specialist } = await supabase
      .from('specialist')
      .select()
      .eq('id_user', user.id);

    if (specialist) {
      setSpecialist(specialist[0]);
      getSchedule(specialist[0]);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        // setUserId(user.id);
        getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const getTimeFromUtc = (time) => {
    return moment(
      moment(`${'2024-01-15'}T${time}:00`).utc().format('YYYY-MM-DDTHH:mm'),
    ).format('HH:mm');
  };

  // Call API to update schedule settings changes
  const updateSchedule = async (
    scheduleAvailability,
    payloadScheduleAvailability,
  ) => {
    const payloadSA = payloadScheduleAvailability.map((psa) => {
      return psa.map((item) => {
        item.startTime = getTimeFromUtc(item.startTime);
        item.startIntevalTime = getTimeFromUtc(item.startIntevalTime);
        item.endIntervalTime = getTimeFromUtc(item.endIntervalTime);
        item.endTime = getTimeFromUtc(item.endTime);
        return item;
      });
    });
    const upsertData = {
      specialist_id: specialist.id,
      schedule_availability: scheduleAvailability,
      schedule: payloadSA,
    };

    try {
      if (upsertData && upsertData.specialist_id) {
        const { error } = await supabase
          .from('schedule')
          .upsert(upsertData, { onConflict: 'specialist_id' })
          .select();
        if (!error) {
          toast.success('Agenda atualizada com sucesso!');
        }
      } else {
        toast.error('Erro ao atualizar agenda!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar agenda!');
    }
  };

  return {
    schedule,
    updateSchedule,
    getSchedule,
  };
}

export default useSchedule;
