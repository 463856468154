/* eslint-disable react/jsx-no-useless-fragment */

import { Route } from 'react-router-dom';
import CancelMeet from '../../../shared/pages/Cancel';
import Legal from '../../../shared/pages/legal';
import { Appointment, Dashboard } from '../pages';
import Profile from '../pages/Settings/Profile';

const Routes = () => {
  console.log('ROUTE ESPEC');

  return (
    <>
      <Route path="/" component={Dashboard} isPrivate />
      <Route path="/Agendamentos" component={Appointment} isPrivate />
      <Route path="/consulta-cancelada" component={CancelMeet} isPrivate />
  
      <Route path="/perfil" component={Profile} isPrivate />
      <Route path="/legal" component={Legal} isPrivate />
    </>
  );
}

export default Routes;
