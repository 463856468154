
import axios from 'axios';
import moment from 'moment';
import toast from 'react-hot-toast';
import getInitials from '../utils/nameInitial';
import { supabase } from './supabase';

async function handleMeeting(
  message,
  closeModal,
  user,
  typeVoucher
) {

  const {
    data: { user: userPatient },
  } = await supabase.auth.getUser();

  const { data: patient } = await supabase
    .from('patient')
    .select()
    .eq('id_user', userPatient.id);


  const endTime = moment(message.time, 'HH:mm')
    .add(50, 'minutes')
    .format('HH:mm');
  const startTime = moment(message.time, 'HH:mm').format('HH:mm');
  const dateDome = moment(message.selectedData).format('YYYY-MM-DD');

  const momentObjStart = moment(dateDome + startTime, 'YYYY-MM-DD HH:mm');
  const momentObjEnd = moment(dateDome + endTime, 'YYYY-MM-DD HH:mm');


  const createEventGoogleCalendar = async () => {
    // Google Calendar
    const event = {
      start: {
        dateTime: momentObjStart,
      },
      end: {
        dateTime: momentObjEnd,
        // timeZone: moment.tz.guess(),
      },
      attendees: [
        {
          email: message.emailSpec,
        },
        {
          email: user.email,
        },
      ],
      summary: `Consulta com ${getInitials(patient[0].name)}`,
      describe: `Terapia ${typeVoucher}`,
    };


    const link = await axios
      .post(
        `${process.env.REACT_APP_URL_BACKEND}/api/insert-event`,
        JSON.stringify(event),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((res) => {
        return res.data?.events?.hangoutLink
      });

    return link;

  }
  // Balance Update
  const balanceUpdate = async () => {
    const dataBalance = await supabase
      .from('balance')
      .select('voucher')
      .eq('patient_id', patient[0].id)
      .eq('type', typeVoucher)
      .order('id', { ascending: false })
      .limit(1);

    if (dataBalance) {
      const link = await createEventGoogleCalendar();
      const dataAppointmentConfirmed = await supabase
        .from('appointment')
        .upsert({
          specialist_id: message.idSpec,
          patient_id: patient[0].id,
          status: 'confirmed',
          start: momentObjStart,
          end: momentObjEnd,
          start_history: momentObjStart,
          end_history: momentObjEnd,
          category: typeVoucher,
          meetLink: link,
        })
        .select();

      const dataBalanceDebit = await supabase
        .from('balance')
        .update({
          voucher: dataBalance?.data[0]?.voucher - 1,
          appointment_id: dataAppointmentConfirmed.data[0]?.id,
        })
        .select('voucher')
        .eq('patient_id', patient[0].id)
        .eq('type', typeVoucher)
        .order('id', { ascending: false })
        .limit(1);

      if (dataAppointmentConfirmed && dataBalanceDebit) {
        message.setTimeMeeting(message.time);
        toast.success("Agendamento Concluído!");
        closeModal();
        window.location.href = "/consultas/cli"
      } else {
        return toast.error('Algo deu errado, tente novamente!');
      }
    }
  };

  balanceUpdate();

}

export default handleMeeting;
