import { Route, Switch } from 'react-router-dom';
import { Cadastro, Login, Register } from '../pages';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import ResetPassword from '../pages/ResetPassword';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/cadastro" exact component={Cadastro} />
      <Route path="/cadastrar" exact component={Register} />
      <Route path="/senha" exact component={ForgotPassword} />
      <Route path="/mudar-senha" exact component={ResetPassword} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default PublicRoutes;