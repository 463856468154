function DashboardStats({ title, icon, value, description, colorIndex }) {
  const COLORS = [];

  const getDescStyle = () => {
    if (description.includes('↗︎'))
      return 'font-bold text-green-700 dark:text-green-300';
    if (description.includes('↙'))
      return 'font-bold text-rose-500 dark:text-red-400';
    return '';
  };

  return (
    <div className="col-span-4 mb-4">
      <div className="stats shadow">
        <div className="stat">
          <div className="stat-figure dark:text-slate-300 text-[#666]">
            {icon}
          </div>
          <div className="stat-title dark:text-slate-300">{title}</div>
          <div className="stat-value dark:text-slate-300 text-[#666]">
            {value}
          </div>
          <div className={`stat-desc  ${getDescStyle()}`}>{description}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardStats;
