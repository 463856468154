import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { NavLink, useLocation } from 'react-router-dom';
import routesAdmin from '../modules/admin/routes/sidebarRoutes';
import routesPatients from '../modules/patients/routes/sidebarRouters';
import routesSpecialists from '../modules/specialist/routes/sidebarRouters';
import { useAuth } from '../shared/context/useAuth';
import SidebarSubmenu from './SidebarSubmenu';

function LeftSidebar() {
  const location = useLocation();
  const { admin, patient, specialist } = useAuth();
  

  const routes =
    (specialist && specialist[0]?.role === 'pro')
      ? routesSpecialists
      : patient && patient[0]?.role === "cli"
        ? routesPatients
        : admin && admin[0]?.role === 'admin' ? routesAdmin : [];

        
  const close = () => {
    document.getElementById('left-sidebar-drawer').click();
  };

  return (
    <div className="drawer-side rounded-t-lg">
      <label htmlFor="left-sidebar-drawer" className="drawer-overlay" />
      <ul className="menu rounded-lg pt-2 w-80 bg-base-100 text-base-content">
        <button
          className="btn btn-ghost bg-[#EFF7FB]  btn-circle z-50 top-0 right-0 mt-4 mr-2 absolute laptop:hidden desktop:hidden"
          onClick={() => close()}
        >
          <XMarkIcon className="h-5 inline-block w-5" />
        </button>

        <li className=" flex items-center w-full	 justify-center">
          <img
            className="w-[170px] hover:bg-white"
            src="/newlogo.png"
            alt="Logo"
          />
        </li>
        {routes?.map((route, k) => (
          <li className="" key={k}>
            {route.submenu ? (
              <SidebarSubmenu {...route} />
            ) : (
              <NavLink
                end
                to={route.path}
                className={(isActive) =>
                  `${
                    isActive
                      ? 'font-semibold bg-[#EFF7FB] dark:text-white text-black'
                      : 'font-normal'
                  }`
                }
              >
                {route.icon} {route.name}
                {location.pathname === route.path ? (
                  <span
                    className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#FEAB31] "
                    aria-hidden="true"
                  />
                ) : null}
              </NavLink>
            )}
          </li>
        ))}

        <span className="text-[#666] text-sm p-2 items-end absolute bottom-0">v.1.0.0</span>
      </ul>

    </div>
  );
}

export default LeftSidebar;
