import { createSlice } from '@reduxjs/toolkit';

const avatarReducer = createSlice({
  name: 'avatar',
  initialState: {
    avatar: null,  // Valor inicial
  },
  reducers: {
    setAvatar: (state, action) => {
      state.avatar = action.payload;  // Atualiza o avatar no estado
    },
  },
});

export const { setAvatar } = avatarReducer.actions;
export default avatarReducer.reducer;
