import '../../../calendar.css';
import { useAuth } from '../../../shared/context/useAuth';
import { AppointmentsSpecialist } from '../../../shared/transactions';

function Dashboard() {
  const { specialist } = useAuth();
  return (
    <>
       {window?.location?.pathname === "/" && (
         <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-12 md:col-span-3 lg:col-span-4">
         <h2 className="font-light text-xl">Bem vindo,</h2>
         <h2 className="font-bold text-2xl mb-6">
           {specialist[0]?.name}
         </h2>
         <p>
           Seja bem vindo a Embrace,
           <br /> Veja suas atividades
         </p>
       </div>
       )}
      <div className="col-span-12">
        <AppointmentsSpecialist />
      </div>
    </>
  );
}

export default Dashboard;
