const NotFound = () => {
  return (
    <div className='w-full flex flex-col items-center mx-auto'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-36 text-[#3BBED0]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
        <h2 className='mobile:text-xl text-3xl font-light mb-8 text-center'>
          Parece que não existe essa página ou você não tem permissão para acessá-la.
        </h2>
        <p className='mobile:text-lg text-2xl mb-6'>Tente novamente.</p>
        <a href='/login'>
          <button className="btn capitalize bg-[#3BBED0] hover:bg-[#3fb7c7] border-none">Faça login</button>
        </a>
    </div>
  )
}

export default NotFound;