import { configureStore } from "@reduxjs/toolkit";
import avatarReducer from '../store/reducers/avatar';
import favoriteSlice from '../store/reducers/favorite';
import modalSlice from "../store/reducers/modal";
import userSlice from '../store/reducers/user';

const store =  configureStore({
  reducer: {
    favorite: favoriteSlice,
    modal: modalSlice,
    avatar: avatarReducer,
    user: userSlice,
  }
});

export default store;
