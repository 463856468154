

import { Route } from 'react-router-dom';
import { default as AppointmentsPatientADMIN, default as AppointmentsSpecADMIN } from '../../../shared/transactions/Patient/index-copy';
import { Dashboard, Graphics, History, Patients, Settings } from '../pages';

function RoutesAdmin() {
  return (
    <>
      <Route path="/" component={Dashboard} isPrivate />
      <Route path="/consultas" component={Graphics} isPrivate />
      <Route path="/pacientes" component={AppointmentsPatientADMIN} isPrivate />
      <Route path="/especialistas" component={AppointmentsSpecADMIN} isPrivate />
      <Route path="/resumo" component={Patients} isPrivate />
      <Route path="/historico" component={History} isPrivate />
      <Route path="/perfil" component={Settings} isPrivate />
    </>
  );
}

export default RoutesAdmin;
