/** Icons are imported separatly to reduce build time */

import CalendarDaysIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';

const iconClasses = `h-6 w-6`;
const submenuIconClasses = 'h-5 w-5';

const routesAdmin = [
  {
    path: '/',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: 'Inicio',
  },

  {
    path: '/historico', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Histórico de consultas', // name that appear in Sidebar
  },
  {
    path: '/pacientes', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Todos Pacientes', // name that appear in Sidebar
  },
  {
    path: '/especialistas', // url
    icon: <CalendarDaysIcon className={iconClasses} />, // icon component
    name: 'Todos Especialistas', // name that appear in Sidebar
  },
  // {
  //   path: '/consultas/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Todas Consultas', // name that appear in Sidebar
  // },
  // {
  //   path: '/conteudo/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Gerencie Conteudos', // name that appear in Sidebar
  // },
  // {
  //   path: '/pagamentos/admin', // url
  //   icon: <CalendarDaysIcon className={iconClasses} />, // icon component
  //   name: 'Transações', // name that appear in Sidebar
  // },

  // {
  //   path: '/pagamento/admin', // url
  //   icon: <CreditCardIcon className={`${iconClasses} inline`} />, // icon component
  //   name: 'Pagamentos Admin', // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: '/pagamento/admin', // url
  //       icon: <CurrencyDollarIcon className={submenuIconClasses} />, // icon component
  //       name: 'Comprar Créditos', // name that appear in Sidebar
  //     },
  //     {
  //       path: '/historico/admin', // url
  //       icon: <WalletIcon className={submenuIconClasses} />, // icon component
  //       name: 'Histórico Pagamentos', // name that appear in Sidebar
  //     },
  //   ],
  // },

  // {
  //   path: '/con', // no url needed as this has submenu
  //   icon: <Cog6ToothIcon className={`${iconClasses} inline`} />, // icon component
  //   name: 'Settings', // name that appear in Sidebar
  //   submenu: [
  //     {
  //       path: '/perfil/admin', // url
  //       icon: <UserIcon className={submenuIconClasses} />, // icon component
  //       name: 'Profile', // name that appear in Sidebar
  //     },
  //   ],
  // },
];

export default routesAdmin;
