
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import TitleCard from '../../../shared/components/Cards/TitleCard';
import SearchBar from '../../../shared/components/Input/SearchBar';
import useAppoitmentsPatientADMIN from '../../../shared/hooks/useAppointment/patient/admin';

function TopSideButtons({ removeFilter, applyFilter, applySearch }) {
  const [filterParam, setFilterParam] = useState('');
  const [searchText, setSearchText] = useState('');
  const locationFilters = ['pending', 'em andamento', 'cancelado', 'confirmed'];

  const showFiltersAndApply = (params) => {
    applyFilter(params);
    setFilterParam(params);
  };

  const removeAppliedFilter = () => {
    removeFilter();
    setFilterParam('');
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      removeAppliedFilter();
    } else {
      applySearch(searchText);
    }
  }, [searchText]);

  return (
    <div className="inline-block float-right">
      <SearchBar
        searchText={searchText}
        styleClass="mr-4"
        setSearchText={setSearchText}
      />
      {filterParam !== '' && (
        <button
          onClick={() => removeAppliedFilter()}
          className="btn btn-xs mr-2 btn-active btn-ghost normal-case"
        >
          {filterParam}
          <XMarkIcon className="w-4 ml-2" />
        </button>
      )}
      <div className="dropdown dropdown-bottom dropdown-end">
        <label tabIndex={0} className="btn btn-sm btn-outline">
          <FunnelIcon className="w-5 mr-2" />
          Filtro
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 text-sm shadow bg-base-100 rounded-box w-52"
        >
          {locationFilters.map((l, k) => (
            <li key={k}>
              <a onClick={() => showFiltersAndApply(l)}>{l}</a>
            </li>
          ))}
          <div className="divider mt-0 mb-0" />
          <li>
            <a onClick={() => removeAppliedFilter()}>Remover Filtro</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

function AppointmentsPatientADMIN() {
  const { data: appointmentsPatientAdmin } = useAppoitmentsPatientADMIN();
  const [trans, setTrans] = useState(appointmentsPatientAdmin);
  useEffect(() => {
    setTrans(appointmentsPatientAdmin);
  }, [appointmentsPatientAdmin]);

  const removeFilter = () => {
    setTrans(appointmentsPatientAdmin);
  };

  const applyFilter = (params) => {
    const filteredTransactions = trans?.filter((t) => t.status === params);
    setTrans(filteredTransactions);
  };

  const copyContent = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Email copiado');
    } catch (err) {
      toast.error('Houve algum erro');
    }
  };

  // Search according to name
  const applySearch = (value) => {
    const filteredTransactions = trans.filter(
      (t) =>
        t.specialist.name.toLowerCase().includes(value.toLowerCase()) ||
        t.specialist.name.toLowerCase().includes(value.toLowerCase()),
    );
    setTrans(filteredTransactions);
  };

  // const allowLink = link?.events[0]?.hangoutLink;
  const getDummyStatus = (index) => {
    if (index === 'pending')
      return <div className="badge badge-warning">Pendente</div>;
    if (index === 'running')
      return <div className="badge badge-primary">Em andamento</div>;
    if (index === 'canceled')
      return <div className="badge badge-secondary">Cancelado</div>;
    if (index === 'confirmed')
      return <div className="badge badge-accent">Confirmado</div>;
    return <div className="badge badge-ghost">Open</div>;
  };

  const pathName = window.location.pathname.split('/')[1];

  return (
    <>
      {pathName !== 'inicio' && (
        <div className="w-full col-span-12">
          <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 tablet:col-span-3 desktop:col-span-4">
            <h2 className="font-bold text-2xl mb-6">
              Vejas todas consultas dos pacientes
            </h2>
            <h2 className="font-light text-xl">Analise completa</h2>
            {/* <p>Acesse links e acompanhe em tempo real o status das consultas</p> */}
            <img
              src="/common/pro2.png"
              alt="doctor"
              className="absolute bottom-0 right-7 mobile:hidden"
              width={190}
            />
          </div>
        </div>
      )}

      <TitleCard
        topMargin="mt-2"
        TopSideButtons={
          <TopSideButtons
            applySearch={applySearch}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
          />
        }
      >
        {/* Team Member list in table format loaded constant */}
        <div className="overflow-x-auto w-full">
          <table className="table w-full overflow-hidden h-full">
            {trans?.length > 0 && (
              <thead>
                <tr>
                  {/* <th>Especialista</th> */}
                  <th>Paciente</th>
                  <th>Tipo de Sessão</th>
                  <th>Id. Meet</th>
                  <th>Data / Hora</th>
                  <th>Status</th>
                </tr>
              </thead>
            )}
            <tbody>
              {trans?.length > 0 ? (
                trans?.map((l, k) => (
                  <tr key={k}>
                    {/* <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold">{l.specialist?.name}</div>
                        </div>
                      </div>
                    </td> */}
                    <td className="font-bold flex ">
                      <div className="indicator">
                        {moment(new Date().toJSON()).isAfter(
                          moment(l.created_at).add(2, 'days'),
                        ) && (
                            <span className="indicator-item badge badge-sm badge-info">
                              {' '}
                            </span>
                          )}
                      </div>
                      <span>
                        {l.patient?.name || 'Paciente nao identificado'}
                      </span>

                      <div className="tooltip" data-tip="Copiar email">
                        <svg
                          onClick={() => copyContent(l.patient?.email)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 ml-4 cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                          />
                        </svg>
                      </div>
                    </td>
                    <td>{l?.category || 'Sem categoria'}</td>
                    <td>{l?.meetLink?.split('/')[3] || 'Sem ID'}</td>
                    <td>
                      {l?.start ? (
                        moment(l.start).format('D MMM HH:mm')
                      ) : (
                        <p className="text-error">Sem data</p>
                      )}
                    </td>
                    <td>{getDummyStatus(l?.status)}</td>
                  </tr>
                ))
              ) : (
                <span className="flex pt-6 text-[#666] font-bold">
                  Nada encontrado.
                </span>
              )}
            </tbody>
          </table>
        </div>
      </TitleCard>
    </>
  );
}

export default AppointmentsPatientADMIN;
