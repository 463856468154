import { decode } from 'base64-arraybuffer';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../../../data/supabase';
import { useAuth } from '../../../shared/context/useAuth';

function useProfile() {
  const [userId, setUserId] = useState('');
  const [media, setMedia] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const { specialist: idSpec, user } = useAuth();
  const getProfile = async (user) => {
    const { data } = await supabase
      .from('specialist')
      .select()
      .eq('id_user', user.id);

    if (data) {
      setSpecialist(data);
    }
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUserId(user.id);
        getProfile(user);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getSpecialties = async () => {
    try {
      const { data } = await supabase.from('specialties').select();
      if (data !== null) {
        setSpecialties(data.map(({ value, label }) => ({ value, label })));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getUser();
    getSpecialties();
  }, []);

  const getMedia = async () => {
    const { data } = await supabase.storage
      .from('uploads')
      .list(`${idSpec[0]?.id}/certified`, {
        limit: 10,
        offset: 0,
        sortBy: {
          column: 'name',
          order: 'asc',
        },
      });

    if (data) {
      setMedia(data);
    }
  };

  const getAvatar = async () => {
    // const { data } = await supabase.storage
    //   .from("uploads")
    //   .list(`${userId}/avatar`, {
    //     limit: 10,
    //     offset: 0,
    //     sortBy: {
    //       column: "name",
    //       order: "asc",
    //     },
    //   });

    const { data } = await supabase.storage
      .from('uploads')
      .getPublicUrl(`${idSpec[0]?.id}/avatar/avatar.jpg`);

    if (data) {
      setAvatar(data);
    }
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    const { data } = await supabase.storage
      .from('uploads')
      .upload(
        `${idSpec[0]?.id}/certified/diploma.${file?.name?.split('.')[1]}`,
        file,
        {
          upsert: true,
        },
      );

    if (data) {
      toast.success('Diploma atualizado');
      getMedia();
    }
  };

  const uploadAvatar = async (e) => {
    try {
      const file = e.target.files[0];
      setAvatar(file);
      const { data } = await supabase.storage
        .from('uploads')
        .insert(
          `${idSpec[0]?.id}/avatar/avatar.${file?.name?.split('.')[1]}`,
          decode(file),
          {
            upsert: true,
          },
        );

      if (data) {
        toast.success('Foto de perfil atualizado');
        getAvatar();
      }
    } catch (error) {
      toast.error('Erro ao atualizar foto de perfil');
    }
  };

  // Call API to update profile settings changes
  const updateProfile = async (specialistData) => {
    try {
      await supabase
        .from('specialist')
        .upsert(
          {
            id: idSpec[0].id,
            id_user: idSpec[0].id_user,
            date_of_birth: specialistData.date_of_birth,
            gender: specialistData.gender,
            email: specialistData.email,
            mobile_number: specialistData.mobile_number,
            telephone_number: specialistData.telephone_number,
            street_name: specialistData.street_name,
            city: specialistData.city,
            state: specialistData.state,
            zip_code: specialistData.zip_code,
            rg_number: specialistData.rg_number,
            cpf_number: specialistData.cpf_number,
            crp_number: specialistData.crp_number,
            country: specialistData.country,
            house_number: specialistData.house_number,
            neighborhood: specialistData.neighborhood,
            name: specialistData.name,
            skills: specialistData.skills,
            typeSession: specialistData.typeSession,
            about: specialistData.about,
            role: specialistData.role,
            allow: specialistData.allow,
          },
          { onConflict: 'id' },
        )
        .select();

      toast.success('Perfil Atualizado');
    } catch (error) {
      toast.error('Erro ao atualizar o perfil');
    }
  };

  const disableProfile = async (specialistLogs) => {
    try {
      await supabase
        .from('specialist_logs')
        .upsert(
          {
            id: specialistLogs.id,
            id_specialist: specialistLogs.specialist[0].id,
            subject: specialistLogs.subject,
            description: specialistLogs.about,
            start_date: specialistLogs.start,
            end_date: specialistLogs.end,
          },
          { onConflict: 'id' },
        )
        .select();
      const specialistData = {
        id: specialistLogs.specialist[0].id,
        allow: false,
      };
      await updateProfile(specialistData);
    } catch (error) {
      toast.error('Erro ao desativar perfil!');
    }
  };

  const updateFormValue = ({ updateType, value }) => {
    setSpecialist({ ...specialist, [updateType]: value });
  };

  return {
    specialist,
    specialties,
    media,
    avatar,
    userId,
    getMedia,
    uploadFile,
    updateProfile,
    updateFormValue,
    uploadAvatar,
    getAvatar,
    disableProfile,
  };
}

export default useProfile;
