import { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import RoutesAdmin from '../../modules/admin/routes';
import RoutePatients from '../../modules/patients/routes';
import RouterSpecialists from '../../modules/specialist/routes';
import NotFound from '../../shared/pages/NotFound';
import PublicRoutes from '../../shared/routes/PublicRoutes';
import { useAuth } from '../context/useAuth';
import LayoutWrapper from '../pages/components/LayoutWrapper';

import pdfEmergency from '../legalDocs/emergencyandcrisis.pdf';
import pdfConsent from '../legalDocs/informedconsent.pdf';
import pdfLegal from '../legalDocs/legal.pdf';
import pdfPolicy from '../legalDocs/privacypolicy.pdf';
import pdfTerms from '../legalDocs/termsofuse.pdf';

const Legal = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfLegal}>
      {' '}
    </iframe>
  );
};

const PrivacyPolicy = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfPolicy}>
      {' '}
    </iframe>
  );
};

const TermsOfUse = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfTerms}>
      {' '}
    </iframe>
  );
};

const InformedConsent = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfConsent}>
      {' '}
    </iframe>
  );
};

const EmergencyAnCrisis = () => {
  return (
    <iframe className="w-full h-full absolute" src={pdfEmergency}>
      {' '}
    </iframe>
  );
};

const Routes = () => {
  const { currentUser, isLoading, patient, specialist, admin } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  
  const hasData = patient.length > 0 || specialist.length > 0 || admin.length > 0;
  
  useEffect(() => {
    if (currentUser !== null) {
      setIsAuthenticated(Object.keys(currentUser).length > 0);
    }
  }, [currentUser]);

  const mainContentRef = useRef(null);
  useEffect(() => {
    mainContentRef?.current?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, []);


  // if (isLoading || isAuthenticated === null) {
  //   return <div>Carregando...</div>;
  // }

  return (
    <div className="drawer">
      <BrowserRouter>
        <FloatingWhatsApp
          accountName="Embrace Internacional"
          allowClickAway
          allowEsc
          avatar="/newlogo.png"
          messageDelay={1}
          statusMessage="Responderemos o mais breve possível"
          chatMessage="Como podemos te ajudar?"
          phoneNumber="+16177024662"
        />


        <Route path="/legal.pdf" exact component={Legal} />
        <Route
          path="/legal/emergencyandcrisis.pdf"
          exact
          component={EmergencyAnCrisis}
        />
        <Route
          path="/legal/privacypolicy.pdf"
          exact
          component={PrivacyPolicy}
        />
        <Route path="/legal/termsofuse.pdf" exact component={TermsOfUse} />
        <Route
          path="/legal/informedconsent.pdf"
          exact
          component={InformedConsent}
        />

        {/* Se não estiver autenticado, renderiza PublicRoutes */}
        {!isAuthenticated ? (
          <PublicRoutes />
        ) : (
          <>
            <LayoutWrapper>
              {/* Renderiza as rotas internas com base no perfil */}
              {patient && patient[0]?.role === "cli" && <RoutePatients />}
              {specialist && specialist[0]?.role === "pro" && <RouterSpecialists />}
              {admin && admin[0]?.role === "admin" && <RoutesAdmin />}
            </LayoutWrapper>
            {hasData && <Route path="*" component={NotFound} />}
          </>
        )}
      </BrowserRouter>
    </div>
  );
};

export default Routes;
