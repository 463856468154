import dayjs from 'dayjs';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { supabase } from '../../../../../data/supabase';
import ButtonSchedule from '../../../../../shared/components/ButtonSchedule/ButtonSchedule';
import { openModal } from '../../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../../utils/globalConstantUtil';
import splitTime from '../../../../../utils/makeTimeIntervals';
import styles from './component.module.css';
import WeekView from './WeekView';

require('dayjs/locale/pt');

dayjs().locale('pt-br');

const HorizontalCalendar = ({
  schedules,
  allData,
  idSpec,
  nameSpec,
  emailSpec,
}) => {
  const selectDate = null;
  const [initialDate, setInitialDate] = useState(() =>
    /* take the initial date if its available and
  make sure the date has a supported format */
    selectDate === null
      ? dayjs()
      : dayjs(selectDate).isValid()
        ? dayjs(selectDate)
        : dayjs(),
  );

  const [selectedDateOfNumber, setSelectedDateOfNumber] = useState();
  const [selectedData, setSelectedData] = useState();

  const forth = () => {
    setInitialDate(initialDate.add(7, 'day'));
  };

  const back = (values) => {
    setInitialDate(initialDate.subtract(7, 'day'));
  };

  const AllScheduleData = {
    allData,
    selectedData,
    idSpec,
  };

  const timeAllow = useCallback(
    () =>
      schedules[selectedDateOfNumber]?.filter((item) => {
        return (
          item.enabled === true &&
          item.dateOfWeek === moment(selectedData).format('DD/MM/YYYY')
        );
      }),
    [JSON.stringify(schedules), selectedDateOfNumber, selectedData],
  );

  const allTimes = timeAllow();


  return (
    <>
      <div className={styles.container}>
        <div className={styles.buttonWrapper}>
          <button onClick={back} className={styles.button}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
            >
              <path
                d="M6 11.5L1 6.5L6 1.5"
                stroke="#3BBED0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <WeekView
          initialDate={initialDate}
          setSelectedDateOfNumber={setSelectedDateOfNumber}
          setSelectedData={setSelectedData}
        />
        <div className={styles.buttonWrapper}>
          <button onClick={forth} className={styles.button}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
            >
              <path
                d="M1 1.5L6 6.5L1 11.5"
                stroke="#3BBED0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      {allTimes && (
        <Meeting
          allScheduleData={AllScheduleData}
          selectedData={selectedData}
          emailSpec={emailSpec}
          enabledTimes={allTimes}
          idSpec={idSpec}
          nameSpec={nameSpec}
        />
      )}
    </>
  );
};

export default HorizontalCalendar;

function Meeting({
  enabledTimes,
  allScheduleData,
  nameSpec,
  idSpec,
  emailSpec,
  selectedData,
}) {
  const dispatch = useDispatch();
  const [time, setTime] = useState();
  const [timeMeeting, setTimeMeeting] = useState();
  const [error, setError] = useState([]);
  const [fetchAppoitment, setFetchAppoitment] = useState([]);
  const openAddNewLeadModal = (time) => {
    dispatch(
      openModal({
        title: 'Resumo do agendamento',
        bodyType: MODAL_BODY_TYPES.CONFIRMATION,
        extraObject: {
          message: {
            ...allScheduleData,
            time,
            nameSpec,
            setTimeMeeting,
            emailSpec,
          },
        },
      }),
    );
  };

  const getTimeFromUtc = (time) => {
    const stillUtc = moment.utc(`${'2024-01-15'}T${time}:00`).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DDTHH:mm');
    return moment(local).format('HH:mm');
  };

  const startTime = enabledTimes.map((item) => getTimeFromUtc(item.startTime));
  const endTime = enabledTimes.map((item) => getTimeFromUtc(item.endTime));
  const date = enabledTimes.map((item) => item.dateOfWeek);

  const timesEnable = splitTime(
    moment({ hour: startTime, date }),
    moment({ hour: endTime, date }),
    60,
  );

  useEffect(() => {
    const fetchAppointment = async () => {
      const { data, error } = await supabase
        .from('appointment')
        .select('*')
        .eq('specialist_id', idSpec);

      if (data) {
        setError(null);
        setFetchAppoitment(
          data.map((item) => {
            return moment(item.start).format('YYYY-MM-DDTHH:mm:ss');
          }),
        );
      }
      if (error) {
        setError(error);
        setFetchAppoitment([]);
      }
    };

    fetchAppointment();
  }, [enabledTimes]);

  const resultFilterData = useCallback(
    () =>
      timesEnable?.filter((item) => {
        return !fetchAppoitment.includes(
          moment(item).format('YYYY-MM-DDTHH:mm:ss'),
        );
      }),
    [fetchAppoitment],
  );

  const resultAll = resultFilterData();
  const [color, setColor] = useState(false);
  const [timeSelectedValue, setTimeSelected] = useState();

  function timeSelected(itemTime) {
    setTime(itemTime);
  }

  function handleTimeSelected(e, index, time) {

    timeSelected(moment(time).format('HH:mm'), index)
    if(moment(time).format('HH:mm') === e.target.innerText.split(' ')[0]){
      setTimeSelected(e.target.innerText.split(' ')[0])
      setColor(true)
    }
  }

  const timeSelect = moment(`${selectedData} ${time}`).format(
    'YYYY-MM-DDTHH:mm:ss',
  );

  return (
    <>
      <div className="px-6 place-content-center mb-6 mt-6 text-center flex gap-2 flex-wrap">
        {resultAll.length > 0 && resultAll[0] !== 'Invalid date' ? (
          resultAll.map((time, index) => {
            return (
              <ButtonSchedule
                handleClick={(e) => handleTimeSelected(e, index, time)
                }
                color={color && moment(time).format('HH:mm') === timeSelectedValue ? 'bg-[#feab31]' : 'bg-[#e5f3ff]'}

              >

                <span className={`text-base font-bold cursor-pointer ${color && moment(time).format('HH:mm') === timeSelectedValue ? 'text-white' : 'text-[#464545]'}`}>
                  {moment(time).format('HH:mm a')}
                </span>
              </ButtonSchedule>
            );
          })
        ) : (
          <p>✖️ Nenhum horário disponivel</p>
        )}
      </div>
      <div className="flex place-content-center">
        <button
          disabled={!(resultAll.length && startTime.length !== 0)}
          onClick={
            moment(new Date().toJSON()).isAfter(moment(timeSelect))
              ? () => toast.warn('Horário Indisponível')
              : () => openAddNewLeadModal(time)
          }
          className={`text-white font-bold p-[12px] ${
            resultAll.length && startTime.length !== 0
              ? 'bg-[#3BBED0] border-[#3BBED0]'
              : 'bg-[#616161] border-[#616161] cursor-not-allowed'
          }  border-2 rounded-[5px]`}
        >
          Agendar
        </button>
      </div>
    </>
  );
}
