import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { supabase } from '../../data/supabase';
import InputText from '../components/Input/InputText';
import { useAuth } from '../context/useAuth';

const Login = () => {
  const INITIAL_LOGIN_OBJ = {
    password: '',
    emailId: '',
  };

  const history = useHistory();
  const { isLoading, currentUser, fetchUserData, patient } = useAuth();

  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);
  const location = useLocation();

  // Detecta quando currentUser muda
   const from = location.state?.from || '/';
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: loginObj.emailId,
        password: loginObj.password,
      });
  
      if (error) {
        toast.error(
          error.message === 'Invalid login credentials'
            ? 'Email ou senha incorretos'
            : error.message === 'missing email or phone' ? 'Digite Email e/ou senha' : error?.message
        );
      } else {
        await fetchUserData();
      }
    } catch (error) {
      toast.error('Algo deu errado, tente novamente!');
    }
  };

  useEffect(() => {
    if (currentUser) {
      // Redireciona para "/" somente depois que a autenticação estiver concluída
      history.push(from);
      console.log('currentUser', currentUser);
      
      toast.success("Bem-vindo! Estamos com você na sua jornada.")
    }
  }, [currentUser, history, from]); // Aguarda a atualização de isAuthenticated
  useEffect(() => {
    console.log('currentUser após login:', currentUser);
  }, [currentUser]);


  if (isLoading) {
    document.body.classList.add('loading-indicator');
  } else {
    document.body.classList.remove('loading-indicator');
    
  }

  const updateFormValue = ({ updateType, value }) => {
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className="max-w-full bg-white flex items-center laptop:pt-12 laptop:pb-12 gap-64 justify-center">
      <div className="laptop:col-span-6 col-span-12 laptop:block hidden">
        <img src="/image.svg" alt="hero" />
      </div>
      <div className="laptop:col-span-6 col-span-12 h-full laptop:p-12 laptop:bg-[#F4F7FB] laptop:rounded-3xl overflow-hidden">
        <div className="flex justify-center flex-col items-center">
          <img
            src="/newlogo.png"
            alt="logo"
            width={120}
            height={120}
            className="justify-center items-center text-center "
          />
          <h2 className="font-work font-bold text-[26px] leading-[54px] -tracking-2 text-center text-black">
            Entrar
          </h2>
        </div>
        <div className="flex items-center justify-center">
          <a
            href={
              '/cadastrar'
            }
          >
            <button className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 mr-6 rounded-full text-sm laptop:text-base text-black p-[24px] bg-[#81818159]">
              Se Cadastrar
            </button>
          </a>
         
            <button className="flex items-center justify-center font-work font-medium leading-4 text-[12px] w-[152px] h-[33px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0]">
              Entrar
            </button>
        </div>

        <form className="mx-auto mt-6 max-w-[450px]" onSubmit={submitForm}>
          <div>
            <InputText
              type="emailId"
              defaultValue={loginObj.emailId}
              updateType="emailId"
              containerStyle="mt-4"
              labelTitle="Email"
              updateFormValue={updateFormValue}
            />

            <InputText
              defaultValue={loginObj.password}
              type="password"
              updateType="password"
              containerStyle="mt-4"
              labelTitle="Senha"
              updateFormValue={updateFormValue}
            />
          </div>

          <div className="mt-6 flex items-center justify-center">
            <button
            type='submit'
              className="flex items-center justify-center font-work font-bold leading-4 text-[16px] w-[249px] h-[52px] px-6 rounded-full text-sm laptop:text-base text-white p-[24px] bg-[#3BBED0] border-[#3BBED0] border-2"
            >
              Entrar
            </button>
          </div>
        </form>
       
        <p className="font-work font-medium leading-5 text-[16px] text-[#3BBED0] mt-8 text-center">
          {' '}
          <a
            href="#"
            onClick={() => history.push("/senha")}
            className="underline decoration-1"
          >
            Esqueceu seu login ou senha?
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
